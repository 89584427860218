import {PrismTheme} from 'prism-react-renderer';

export const Theme: PrismTheme = {
	plain: {
		color: 'var(--color--background)',
		backgroundColor: 'var(--color--background-dark)',
	},
	styles: [
		{
			types: ['comment', 'prolog', 'doctype', 'cdata'],
			style: {
				color: 'var(--color--green)',
				fontStyle: 'italic',
			},
		},
		{
			types: ['namespace'],
			style: {
				opacity: 0.7,
			},
		},
		{
			types: ['string', 'attr-value'],
			style: {
				color: 'var(--color--pink)',
			},
		},
		{
			types: ['punctuation', 'operator'],
			style: {
				color: 'var(--color--blue)',
			},
		},
		{
			types: [
				'entity',
				'url',
				'symbol',
				'number',
				'boolean',
				'variable',
				'constant',
				'property',
				'regex',
				'inserted',
			],
			style: {
				color: 'var(--color--yellow)',
			},
		},
		{
			types: ['atrule', 'keyword', 'attr-name', 'selector'],
			style: {
				color: 'var(--color--red)',
			},
		},
		{
			types: ['function', 'deleted', 'tag'],
			style: {
				color: 'var(--color--red)',
			},
		},
		{
			types: ['function-variable'],
			style: {
				color: 'var(--color--purple)',
			},
		},
		{
			types: ['tag', 'selector', 'keyword'],
			style: {
				color: 'var(--color--orange)',
			},
		},
	],
};
