import React from 'react';
import {MDXProvider} from '@mdx-js/react';
import {MDXRenderer} from 'gatsby-plugin-mdx';
import {Link, graphql} from 'gatsby';
import {SEO, ContentLayout, Page, Panel, Tag, Canvas} from '..';
import {CodeHighlight, Figure, Gitalk} from './components';
import style from './BlogLayout.module.css';
import 'katex/dist/katex.min.css';
import {OutboundLink} from 'gatsby-plugin-google-analytics';

interface Props {
	data: {
		mdx: {
			id: string;
			frontmatter: {
				title: string;
				date: string;
				description: string;
				tags?: string[];
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				featuredImage: any;
			};
			body: string;
		};
	};
}

const shortcodes = {Link, code: CodeHighlight, Figure, Canvas};

export default function BlogLayout({data: {mdx}}: Props) {
	const tags = mdx.frontmatter.tags?.map((tag, index) => {
		return <Tag key={index}>{tag}</Tag>;
	});

	return (
		<>
			<SEO title={mdx.frontmatter.title} />
			<div id="portal-root" />
			<ContentLayout title="blog" titleLink="/blog">
				<Page
					title={mdx.frontmatter.title}
					subTitle={mdx.frontmatter.date}
					heroImage={mdx.frontmatter.featuredImage.childImageSharp.fluid}
				>
					<Panel>
						<div className={style.Link}>
							<Link to="/blog">Back to All Posts</Link>
						</div>
						<br />
						<div className={style.Blog}>
							<div className={style.BlogContent}>
								<h1>{mdx.frontmatter.title}</h1>
								<div>{tags}</div>
								<br />
								<MDXProvider components={shortcodes}>
									<MDXRenderer>{mdx.body}</MDXRenderer>
								</MDXProvider>
							</div>
							<hr />
							<div className={style.Footer}>
								<h3>justin mills 🏃</h3>
								<h5>
									Lover of building things. Thank you for exploring ideas with
									me.
								</h5>
								<OutboundLink
									href="https://jtsmills.com/rss.xml"
									target="_blank"
									rel="noreferrer"
								>
									Subscribe to this blog with my RSS feed
								</OutboundLink>
							</div>
							<div>
								<h4>Conversation</h4>
								<Gitalk
									id={mdx.id}
									title={mdx.frontmatter.title}
									labels={mdx.frontmatter.tags}
								/>
							</div>
						</div>
					</Panel>
				</Page>
			</ContentLayout>
		</>
	);
}

export const blogQuery = graphql`
	query BlogPostQuery($id: String) {
		mdx(id: {eq: $id}) {
			id
			body
			frontmatter {
				title
				date(formatString: "YYYY MMMM Do")
				tags
				featuredImage {
					childImageSharp {
						fluid(quality: 100, maxWidth: 1440) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
			}
		}
	}
`;
