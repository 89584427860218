import React from 'react';
const isBrowser = typeof window !== 'undefined';
const GitalkComponent = isBrowser
	? require('gitalk/dist/gitalk-component')
	: undefined;
import 'gitalk/dist/gitalk.css';

interface GitalkOptions {
	id: string;
	title: string;
	labels?: string[];
}

export function Gitalk(options: GitalkOptions) {
	if (GitalkComponent == null) {
		return null;
	}

	return (
		<GitalkComponent
			options={{
				clientID: `ff9b759e9f224fc46d5e`,
				clientSecret: `24e996ff264f1866daccef91270268e70334c968`,
				repo: `jtsmills-comments`,
				owner: `jtsmills`,
				admin: [`jtsmills`],
				distractionFreeMode: false,
				...options,
			}}
		/>
	);
}
