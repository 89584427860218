import React from 'react';
import classNames from 'classnames';
import Highlight, {defaultProps, Language} from 'prism-react-renderer';
import style from './CodeHighlight.module.css';
import {Theme} from './Theme';

export function CodeHighlight({
	children,
	className,
}: {
	children: string;
	className: string;
}) {
	const language = className?.replace(/language-/, '') ?? 'typescript';
	return (
		<Highlight
			{...defaultProps}
			code={children}
			language={language as Language}
			theme={Theme}
		>
			{({
				className,
				style: highlightStyle,
				tokens,
				getLineProps,
				getTokenProps,
			}) => {
				const classes = classNames(style.CodeHighlight, className);

				return (
					<div className={classes} style={{...highlightStyle}}>
						{tokens.map((line, i) => (
							<div
								key={i}
								{...getLineProps({line, key: i})}
								className={style.Line}
							>
								<div className={style.LineNumber}>{i + 1}</div>
								{line.map((token, key) => (
									<span key={key} {...getTokenProps({token, key})} />
								))}
							</div>
						))}
					</div>
				);
			}}
		</Highlight>
	);
}
