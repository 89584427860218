import React from 'react';
import style from './Figure.module.css';

interface Props {
	caption?: React.ReactNode;
	alt: string;
	src: string;
	video?: boolean;
}

export function Figure({src, alt, caption, video}: Props) {
	const captionMarkup = caption ? (
		<figcaption className={style.FigCaption}>{caption}</figcaption>
	) : null;

	if (video) {
		return (
			<figure className={style.Figure}>
				<video
					autoPlay
					playsInline
					loop
					className={style.FigureVideo}
					src={src}
				/>
				{captionMarkup}
			</figure>
		);
	}

	return (
		<figure className={style.Figure}>
			<img className={style.FigureImage} src={src} alt={alt} />
			{captionMarkup}
		</figure>
	);
}
